.hey {
      color: #88B04B;
      font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
      font-weight: 900;
      font-size: 30px;
      margin-bottom: 10px;
      text-align: center;
    }
.checkmark {
    position: absolute;
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
    transform: translate(90%, 0%);
  }
  .circle{
    border-radius: 200px;
    height:200px;
    width:200px;
    background: #F8FAF5;
    margin: 0 auto;
  }
  .card123 {
    min-width: 450px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
  }
  

  .card123 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .circle {
    background-color: #333;
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .hey {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .feedback-input {
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
    font-size: 14px;
    color: #333;
    background-color: #fff;
  }
  
  .feedback-input:focus {
    outline: none;
    border-color: #333;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #333;
  }
  
  p {
    /* font-size: 14px; */
    color: #666;
  }
  
  .redirecting-message {
    color: #333;
    font-weight: bold;
  }
  