

section > div {
    padding: 15px;
  }
  .tablet {
    display: flex;
    margin: 0 auto;
    background: #0c0c0c;
    border-radius: 37px;
    border: 2px solid #3a3a3a;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 1px 7px 19px #00000061, 1px 14px 38px #00000080,
      inset 0 0 4px rgba(255, 255, 255, 0.58);
    overflow: hidden;
  }
  .tablet.portrait {
    height: 645px;
    width: 490px;
    transform: scale(0.9);
  }
  .tablet.portrait .tablet__screen {
    height: 84%;
    width: 89%;
  }
  .tablet.portrait .tablet__home {
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
  }
  .tablet.portrait .tablet__camera {
    top: 27px;
    left: 50%;
    transform: translateX(-50%);
  }
  .tablet.portrait .tablet__screenContainer:before {
    width: 150%;
    top: 20%;
    left: -6%;
    background-image: linear-gradient(180deg, transparent, #9a9a9a2b 100%);
    mix-blend-mode: lighten;
  }
  .tablet::before {
    display: block;
    height: 25%;
    width: 120%;
    content: "";
    position: absolute;
    top: 20%;
    background: linear-gradient(180deg, transparent, #ffffff38 100%);
    transform: rotate(12deg);
    filter: blur(1px);
    z-index: 1;
  }
  .tablet__screen {
    background-color: #1b1b1b;
    background-size: cover;
    background-position: center;
    z-index: 2;
    overflow: hidden;
  }
  .tablet__screenContainer {
    height: 100%;
    width: 98%;
    left: 2px;
    position: relative;
    box-shadow: inset 0px 0px 2px #000000a3;
    overflow: hidden;
  }
  .tablet__screenContainer:before {
    display: block;
    height: 25%;
    content: "";
    position: absolute;
    transform: rotate(12deg);
    filter: blur(10px);
    pointer-events: none;
    z-index: 3;
  }
  .tablet__camera {
    height: 8px;
    width: 8px;
    background: linear-gradient(270deg, #4a4a4a, transparent);
    border-radius: 50%;
    position: absolute;
    display: -webkit-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tablet__cameraEye {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: #0045ad;
  }
  .tablet__home {
        height: 28px;
      width: 28px;
      background: linear-gradient(90deg, #5656564f, #141414);
    border-radius: 50%;
    position: absolute;
    display: grid;
    place-content: center;
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
  .tablet__homeSquare {
    height: 12px;
    width: 12px;
    border-radius: 3px;
    border: 1px solid #4c4c4c;
  }
  
  /**********************/
  
  /*! CSS Used from: https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css */
  .ion-ios-circle-outline:before,
  .ion-ios-list:before,
  .ion-ios-person-outline:before {
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ion-ios-circle-outline:before {
    content: "\f401";
  }
  .ion-ios-list:before {
    content: "\f454";
  }
  .ion-ios-person-outline:before {
    content: "\f47d";
  }
  
  /*! CSS Used from: Embedded */
  .wrap {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fffffff0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.19);
    font-family: "Open Sans", sans-serif;
    overflow: auto;
  }
  
  .top {
    flex: 1;
      display: flex;
      justify-content: space-between;
      padding: 6px 6px 3px 5px;
      max-height: 30px;
      /*box-shadow: #0004 0 1px 2px 0, #0003 0 0.5px 2px 1px, 1px 1px 10px 2px #00000026;*/
      background-color: #fff;
      background-image: linear-gradient( 180deg, #ffffff 10%, hsl(216, 4%, 85%) 60%, 80%, hsl(216, 4%, 70%) );
  }
  
  
  .top .network {
    display: flex;
  }
  .top .network .span {
    margin: 1px;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 100%;
  }
  .top .network .span:last-child {
    margin-left: 1.4px;
    width: 4px;
    height: 4px;
    background: #fff;
    border: thin solid #000;
  }
  .top .time {
    font-size: 0.7rem;
    font-weight: bolder;
    margin-right: 20px;
    margin-top: -2px;
  }
  .top .battery {
    width: 19px;
    height: 7px;
    background: #52c41a;
    border-radius: 2px;
    position: relative;
  }
  .top .battery:after {
    position: absolute;
    top: -2px;
    left: -2px;
    content: "";
    width: 21px;
    height: 9px;
    border: 1px solid #4d4d4d;
    border-radius: 2px;
  }
  .mid {
    flex: 9;
    padding: 10px;
    display: flex;
    flex-direction: column;
    white-space: pre-line;
    word-break: break-word;
  }
  
  .mid .june-wrap,
  .mid .july-wrap {
    display: flex;
    flex-direction: column;
  }
  .mid .june-wrap .june-heading,
  .mid .july-wrap .july-heading {
    display: flex;
    flex-direction: column;
  }
  .mid .june-wrap .june-heading span:first-child,
  .mid .july-wrap .july-heading span:first-child {
    font-size: 20px;
    padding: 5px 0;
        font-weight: 500;
      letter-spacing: 0.5px;
  }
  .mid .june-wrap .june-heading span:last-child,
  .mid .july-wrap .july-heading span:last-child {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6a6;
    letter-spacing: 0.7px;
  }
  .appointments-wrap {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
  }
  .each-appointment {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
  }
  .each-appointment .date {
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    align-content: center;
    align-items: center;
  }
  .each-appointment .date span:first-child {
    font-size: 1.2rem;
  }
  .each-appointment .date span:last-child {
    font-size: 0.8rem;
    font-size: 13px;
    color: #a6a6a6;
    font-weight: 400;
  }
  .each-appointment .date span:first-child {
    font-weight: 500;
  }
    
  .each-appointment .title {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
  }
  .each-appointment .title span:first-child {
    padding-bottom: 3px;
    font-weight: 500;
  }
  .each-appointment .title span:last-child {
    font-size: 14px;
    color: #a6a6a6;
    font-weight: 400;
  }
  .avatar-image {
    height: 50px;
    width: 50px;
    background: #a6a6a6;
    border-radius: 100%;
    margin: 0 10px;
  }
  .bottom {
    flex: 1;
    display: flex;
        background: #8b949e33;
  
  }
  .bottom .nav-btn-left,
  .bottom .nav-btn-middle,
  .bottom .nav-btn-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  
    cursor: pointer;
    transition: all 500ms ease-out;
  }
  .bottom .nav-btn-left:hover,
  .bottom .nav-btn-middle:hover,
  .bottom .nav-btn-right:hover {
    border-top: thin solid #e6e6e6;
  }
  
  @media (max-height: 700px) {
    .tablet.portrait {
      height: 500px;
      width: 365px;
      transform: scale(0.95);
    }
  
    .tablet.portrait .tablet__camera {
      top: 18px;
    }
  
    .tablet.portrait .tablet__home {
      bottom: 15px;
    }
  
    .tablet.portrait .tablet__screen {
      height: 82%;
          margin-top: -6px;
  
    }
  
    .mid {
      margin-left: 16px;
    }
  
    .each-appointment {
      padding: 4px 0;
    }
  
    .each-appointment .date {
      padding: 2px 8px;
    }
  
    .avatar-image {
      height: 33px;
      width: 33px;
      margin: 0 8px;
    }
  
    .each-appointment .title {
      padding-top: 4px;
    }
  
    .each-appointment .date span:first-child {
      font-size: 18px;
    }
  
    .each-appointment .title span:first-child {
      padding-bottom: 2px;
      font-weight: 500;
      font-size: 15px;
    }
  
    .mid .june-wrap .june-heading span:first-child,
    .mid .july-wrap .july-heading span:first-child {
      font-size: 18px;
      padding: 0.25px 0;
    }
  
    .mid .june-wrap .june-heading span:last-child,
    .mid .july-wrap .july-heading span:last-child {
      font-size: 13px;
    }
  
    .bottom .nav-btn-left,
    .bottom .nav-btn-middle,
    .bottom .nav-btn-right {
      font-size: 24px;
    }
  }
  
  @media (max-height: 600px) {
    .tablet.portrait {
      transform: scale(0.8);
    }
  }
  
  @media (max-height: 480px) {
    .tablet.portrait {
      transform: scale(0.74);
    }
  }
  
  @media (max-height: 405px) {
    .tablet.portrait {
      transform: scale(0.65);
    }
  }
  
  
  
  @media (max-height: 315px) {
  .tablet.portrait {
      transform: scale(0.45);
  }
  }
  .wrap::-webkit-scrollbar {
    width: 4px ;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  p{
    margin-bottom: 0;
  }