/* :root{
    --cart-component-font-size:52.5%;
} */
.cart-component input:focus {
    outline: none;
    border: 1px solid #ccc; /* Set the border color you desire */
  }
  .cart-component input{
    border: 1px solid #ccc; 
    padding: 1.5px;
    border-radius: 5px;
  }
.cart-component{
    font-size: 52.5% !important;
}

.cart-component .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.2rem;
    width: 85%;
    max-width: 50rem;
    padding: 2rem;
    color: #191a3c;
    background-color: #fff;
    border-radius: 3rem;
    position: relative;
    transition: all .3s linear;
}
.cart-component .container::before{
    content: '';
    position: absolute;
    background-color: #b6d2e3;
    top: -1rem;
    right: -2rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 3rem;
    box-shadow: 0 7px 50px #b6d2e3;
}
/* .cart-component .title-box >h1 {
} */
.cart-component .title-box >p{
    margin-top: .5rem;
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: .03rem;
}
.cart-component .product-box{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.cart-component img{
    width: 5.5rem;
}
.cart-component .info-box >h5{
    font-size: 0.7rem;
}
.cart-component .info-box>p{
    margin-top: 0.5rem;
    font-size: 1.2rem;
    background-color: #83bd46;
    color: #fff;
    width: fit-content;
    padding: .3rem 1.1rem;
    border-radius: 2rem;
}
.cart-component .info-box>.amount-box{
    margin-top: 1rem;
    display: flex;
    gap: .3rem;
}
.cart-component .info-box>.amount-box >div{
    width: 2.5rem;
    border-radius: .3rem;
    aspect-ratio: 1;
    background-color: #e0f2f9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: #191a3cee;
    cursor: pointer;
}
.cart-component .amount{
    width: 2.5rem;
    border: 1px solid #d1d1d1;
    outline: none;
    border-radius: .3rem;
    text-align: center;
    color: #191a3c;
}
.cart-component input[type="number"]::-webkit-inner-spin-button,
.cart-component input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart-component input[type="number"] {
  -moz-appearance: textfield;
}
.cart-component hr{
    border: none;
    border-top: 1px solid #e9e9e9;
}
.cart-component .cost-box{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.cart-component .cost-box >div{
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
}
.cart-component .cost-box>div:nth-of-type(1)>p:nth-of-type(2){
    font-weight: bold;
}
.cart-component .cost-box>div:nth-of-type(2)>p:nth-of-type(2){
    font-weight: bold;
}
.cart-component .cost-box>div:nth-of-type(3)>p{
    font-weight: bold;
}
.cart-component .cost-box>p{
    color: #5381f8;
    font-size: 0.7rem;
    text-decoration: underline;
    cursor: pointer;
}
.cart-component .applied{
    position: absolute;
    background-color: #191a3c;
    border-radius: 5px 0 0 5px;
    color: #fff;
    padding: .5rem 1rem;
    bottom: 9.6rem;
    right: 0;
    opacity: 0;
    transition: all .3s linear;
}
.cart-component .Checkout-btn{
    position: relative;
    margin-top: .5rem;
    width: 40%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #5381f8;
    border: none;
    color: #fff;
    padding: 0.3rem 0.3rem;
    font-size: 0.9rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: all .25s linear;
}
.cart-component .Checkout-btn:hover{
    background-color: #fff;
    color: #191a3c;
    box-shadow: inset 0 0 0 1px #191a3c,
             inset 10px 0 0 6px #191a3c;
}
.cart-component .promo-box{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 2rem;
    background-color: #191a3c;
    border-radius: 1rem;
    color: #fff;
    opacity: 0;
    display: none;
    transition: all .3s linear;
}
.cart-component .promo-box>h5{
    font-size: 0.6rem;
}
.cart-component #promo{
    width: 10rem;
    outline: none;
    border: none;
    padding: .4rem 1rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.7rem;
}
.cart-component .send-promo{
    border: none;
    outline: none;
    padding: .2rem .6rem;
    border-radius: 3px;
    /* margin-top: .3rem; */
    color: #191a3c;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-weight: bold;
}
.MuiTableCell-root {
    padding: 5px !important;
  }
  