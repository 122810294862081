
.iphone {
    margin: auto;
    border: 10px solid black;
    border-radius: 40px;
    width: 300px;
    height: 600px;
    position: relative;
    box-shadow: 0px 20px 15px #33333335;
}
  
  .notch {
    z-index:50;
    top:0;
    left:62px;
    position:absolute;
    background:#000000;
    width:166px;
    height:30px;
    border-radius:0px 0px 20px 20px;
  }
  
  .screen {
    z-index:10;
    position:absolute;
    background:url("https://cdn57.androidauthority.net/wp-content/uploads/2020/10/iPhone-12-Orb-Blue-Light.jpg");
    background-size:cover;
    width:100%;
    height:100%;
    border-radius:30px;
  background:white;
  overflow: auto;
  padding: 40px 10px 10px 10px;
  white-space: pre-line;
  word-break: break-word;
  }
  
  
  .screen::-webkit-scrollbar {
    width: 0px ;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  p{
    margin-bottom: 0;
  }
  
  
  
  
  
  