



.container-div {
  border: 4px solid #9199a5;
  border-radius: 35px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 550px;
  width: 435px;
  position: relative;
  z-index: 100;
}
.container-div .content {
  background: #fafbfb;
  border: 23px solid #1e2026;
  border-radius: 32px;
  height: 550px;
  width: 435px;
  overflow-y: scroll;
}
p{
  margin-bottom: 0;
}
