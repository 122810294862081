/* src/components/BookEditor.css */

.book-editor-container {
  display: flex;
  height: calc(100vh - 60px);
  background-color: #f0f0f0;
  width: 100vw;
}

.menu1 {
  width: 15%;
  background-color: #333;
  color: white;
  padding: 20px;
  /* overflow-y: scroll; */
}

.menu1 button, .menu1 input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.menu1 button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.menu1 button:hover {
  background-color: #45a049;
}

.menu1 input[type="color"] {
  padding: 0;
  height: 40px;
  cursor: pointer;
}

.book-editor {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */
  width: 85%;
  height: calc(100vh - 100px);
  position: relative;
}
.book-editor-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* flex-grow: 1;
  width: 85%;
  height: calc(100vh - 100px); */
}
.left-board{
  padding: 0.5in 0 0.5in 0.5in; /* Bleed area */
  border-top: 1px solid black;    /* Top border */
  border-left: 1px solid black;   /* Left border */
  border-bottom: 1px solid black; /* Bottom border */
  border-right: none; 
}

.bleed-area {
  padding: 0.125in 0 0.125in 0.125in; /* Bleed area */
  border-top: 1px solid black;    /* Top border */
  border-left: 1px solid black;   /* Left border */
  border-bottom: 1px solid black; /* Bottom border */
  border-right: none; 
  position: relative;
}

.caution-area {
  padding: 0.125in 0 0.125in 0.125in; /* Caution area */
  box-sizing: border-box;
  border-top: 1px solid black;    /* Top border */
  border-left: 1px solid black;   /* Left border */
  border-bottom: 1px solid black; /* Bottom border */
  border-right: none; 
  position: relative;
}
.right-board{
  padding: 0.5in 0.5in 0.5in 0; /* Bleed area */
  border-top: 1px solid black;    /* Top border */
  border-left: none;   /* Left border */
  border-bottom: 1px solid black; /* Bottom border */
  border-right: 1px solid black; 
}
.bleed-area-right {
  padding: 0.125in 0.125in 0.125in 0; /* Bleed area */
  border-top: 1px solid black;    /* Top border */
  border-left: none;   /* Left border */
  border-bottom: 1px solid black; /* Bottom border */
  border-right: 1px solid black; 

}

.caution-area-right {
  padding: 0.125in 0.125in 0.125in 0; /* Caution area */
  box-sizing: border-box;
  border-top: 1px solid black;    /* Top border */
  border-left: none;   /* Left border */
  border-bottom: 1px solid black; /* Bottom border */
  border-right: 1px solid black; 

}
.book-layout {
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.book-cover, .book-front-spine,.book-back-spine, .book-back-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  color: black;
  font-size: 1.5em;
  position: relative; /* Added for positioning text boxes */
}

.book-cover {
  width: 8.5in;
  height: 11in;
}

.book-front-spine {
  width: 0.5in;
  height: 11in;
}
.book-back-spine {
  width: 0.5in;
  height: 11in;
}

.book-back-cover {
  width: 8.5in;
  height: 11in;
}
.preview-mode {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.preview-cover {
  /* margin: 5px; */
  border: none; /* Ensure no borders in preview mode */
}

.text-area:focus {
  border: 1px dashed #000;
  background-color: #fffefe;
}
.upload-button-parent{
  display: flex;
  justify-content: center
}
 
.upload-button{
  z-index: 999;

  ;
}
.resize-handle {
  background: #fffefe;
}