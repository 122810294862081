/* 
.sidebar-border {
    background-color: var(--bg-secondary); 
    box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease;
    box-shadow: 0px 0 12px rgba(0, 0, 0, 0.05);
}

.nav {
    position: fixed;
    width: 80px;
    height: 100%;
    background-color: var(--bg-secondary); 
    transition: width 0.4s ease;
    overflow: hidden;
    padding-top: 20px;
}

.nav.active {
    width: 260px;
    margin-top: 80px;
}

.nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav ul li {
    margin-bottom: 1.2rem;
}

.nav ul li a {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    text-decoration: none;
    color: var(--cl-text-dark); 
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
}

.nav ul li .icon {
    font-size: 1.6rem;
    color: var(--cl-text-dark); 
    transition: color 0.3s ease, transform 0.3s ease;
    margin-right: 16px;
}

.nav ul li .title {
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.nav.active ul li .title {
    opacity: 1;
}

.nav ul li a:hover {
    background-color: var(--bg-hover); 
    color: var(--cl-text-hover); 
}

.nav ul li a:hover .icon {
    color: var(--cl-text-hover); 
    transform: scale(1.1); 
}

.nav ul li a.active,
.nav ul li a.active .icon {
    background-color: var(--bg-active);
    color: var(--cl-text-hover); 
}

.menu-toggle {
    margin-bottom: 2rem;
}

.nav ul li .menu-toggle .icon {
    font-size: 2rem;
}

.nav.active ul li .menu-toggle .icon {
    transform: rotate(90deg);
}

@media (max-width: 768px) {
    .nav {
        width: 60px;
    }

    .nav.active {
        width: 100%;
    }

    .nav ul li .title {
        display: none;
    }

    .nav.active ul li .title {
        display: inline;
    }
} */
/* 
:root {
    --bg-primary: #6C757D; 
    --bg-secondary: #ffffff; 
    --bg-active: rgba(106, 26, 195, 0.1);
    --bg-hover: #1e1c1f; 
    --cl-text-dark: #6C757D;
    --cl-text-hover: #fff; 
} */


/* Sidebar Styles */


.nav {
    /* position: fixed; */
    width: 80px;
    height: 100vh;
    /* background-image: linear-gradient(to right, #8e2de2, #4a00e0); Purple gradient background */
    /* background-color: transparent; */
    transition: width 0.4s ease;
    overflow: hidden;
    padding-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* border: 1px solid #8e2de2; */
    border-top: none;
}

.nav.active {
    width: 260px;
    align-items: baseline;
    /* margin-top: 80px; */
}

.nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav ul li {
    margin-bottom: 1.2rem;
}

.nav ul li a {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    text-decoration: none;
    color: #1f1f1f; /* White text color */
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
}

.nav ul li .icon {
    font-size: 1.6rem;
    color: #1f1f1f; /* White icon color */
    transition: color 0.3s ease, transform 0.3s ease;
    margin-right: 16px;
}

.nav ul li .title {
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.nav.active ul li .title {
    opacity: 1;
}

.nav ul li a:hover {
    background-color: #1f1f1f; /* Light purple background on hover */
    color: #ffffff; /* White text on hover */
}

.nav ul li a:hover .icon {
    color: #ffffff; /* White icon on hover */
    transform: scale(1.1); /* Slight scale up on hover */
}

.nav ul li a.active,
.nav ul li a.active .icon {
    background-color: #1f1f1f; /* Darker purple for active link background */
    color: #ffffff; /* White text color for active state */
}

.menu-toggle {
    margin-bottom: 2rem;
}

.nav ul li .menu-toggle .icon {
    font-size: 2rem;
}

.nav.active ul li .menu-toggle .icon {
    transform: rotate(90deg);
}

@media (max-width: 768px) {
    .nav {
        width: 60px;
    }

    .nav.active {
        width: 100%;
    }

    .nav ul li .title {
        display: none;
    }

    .nav.active ul li .title {
        display: inline;
    }
}
