@import url("https://rsms.me/inter/inter.css");

:root {
  --color-gray: #737888;
  --color-lighter-gray: #e3e5ed;
  --color-light-gray: #f7f7fa;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body {
  margin: 0;
}

h1 {
  margin-bottom: 1rem;
}

p {
  color: var(--color-gray);
}

hr {
  height: 1px;
  width: 100%;
  background-color: var(--color-light-gray);
  border: 0;
  margin: 2rem 0;
}

.container {
  max-width: 75rem;
  padding: 6vw 2rem 0;
  margin: 0 auto;
  /* height: 100vh; */
}

.form {
  /* max-width: 40rem; */
  display: grid;
  grid-gap: 1rem;
}
hr{
  background-color: #000;
}
.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-lighter-gray);
  padding: .5rem;
  border-radius: .25rem;
}

.field__label {
  color: var(--color-gray);
  font-size: 0.6rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.field__input {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}

.field:focus-within {
  border-color: #000;
}

.fields {
  display: grid;
  grid-gap: 1rem;
}

.fields--2 {
  grid-template-columns: 1fr 1fr;
}

.fields--3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.button {
  background-color: #000;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  display: block;
  color: #fff;
  width: 100%;
  padding: 1rem;
  border-radius: 0.50rem;
  border: 0;
  cursor: pointer;
  outline: 0;
}
.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.button:focus-visible {
  background-color: #333;
}


.checkout-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}

.shipping-form,
.payment-summary {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  flex: 1;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

p {
  /* font-size: 16px; */
  margin-bottom: 5px;
  color: #555;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.fields {
  display: flex;
  gap: 20px;
}

.field__label {
  font-size: 14px;
  margin-bottom: 5px;
}

.field__input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}


.button:disabled {
  background-color: #ccc;
}

.payment-summary {
  height: fit-content;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total {
  font-size: 18px;
}

hr {
  border: none;
  border-top: 1px solid #eee;
  margin: 20px 0;
}
