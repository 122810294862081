/* Toolbar.css */
.toolbar {
    position: absolute;
    background: white;
    border: 1px solid black;
    padding: 5px;
    z-index: 1000;
    display: flex;
    gap: 5px;
  }
  .soft-cover-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out; /* Adjust duration and easing as needed */
  }
  
  .soft-cover-options.open {
    max-height: 350px; /* Adjust height based on your content */
  }
  .soft-cover-header {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hard-cover-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out; /* Adjust duration and easing as needed */
  }
  
  .hard-cover-options.open {
    max-height: 350px; /* Adjust height based on your content */
  }
  .hard-cover-header {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  