.react-pdf__Document{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
  }
.buttonpreview{
  background-color: white;
  border: 1px solid #EFEFEF;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
.button-box{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}