/* Navbar.css */

.nav-wrapper {
  /* background: linear-gradient(to right, #8e2de2, #4a00e0); Purple gradient background */
  padding: 0.8rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid black;
  
}

.logo-container {
  cursor: pointer;
}

.logo {
  width: 150px; /* Adjust the size to fit the navbar */
  height: auto;
}

nav {
  display: flex;
  align-items: center;
}

.nav-tabs {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-tab {
  color: #1f1f1f; /* White text color */
  margin-left: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.nav-tab:hover {
  color: #404040; /* Subtle hover effect matching signup/signin hover */
}

.hidden {
  display: none;
}

/* Mobile Menu Toggle */
.menu-btn {
  display: none;
  cursor: pointer;
}

.menu {
  width: 25px;
  height: 3px;
  background-color: white; /* White lines for the hamburger menu */
  margin: 5px 0;
}

#menuToggle:checked + .nav-container {
  display: block;
}

@media (max-width: 768px) {
  .nav-container {
    display: none;
    flex-direction: column;
    /* background: linear-gradient(to right, #8e2de2, #4a00e0); Matching gradient */
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    padding: 1rem;
  }

  .nav-tab {
    margin: 1rem 0;
    text-align: center;
  }

  .menu-btn {
    display: block;
  }
}
