.ql-container.ql-snow {
  border: 0;
}


.ql-toolbar.ql-snow {
  position: sticky;
  top:0;
  z-index: 1;
  border: 1px solid #30363d;
  background-color: white;
  border: 0;
  border-left:2px solid #503981;
  border-right:2px solid #503981;
}
#root .ql-snow.ql-toolbar {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.ql-card-editable{
  cursor: grab;
}
.scene_break{
  display: flex;
  justify-content: center;
}

.ql-card-editable img{
  cursor: grab !important;
}

#root .ql-container{
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-left:2px solid #503981 !important;
  border-right:2px solid #503981 !important;
  border-bottom:2px solid #503981 !important;
}
.ql-container {
  background-color: white;

}
.ql-editor p {
  font-size: 18px;
}  

.ql-editor p img {
  /* margin: 20px ; */
}


.ql-container {
  border-right: 0;
}
.editor-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
}
.editor-container input {
  border: none;
}
.editor-container input:active {
  border: none;
  border-bottom: 1px solid black;
}

.icons-container {
  display: flex;
  justify-content: space-between;
}
.icons-container svg {
  cursor: pointer;
  margin: 0 20px;
}
.ql-editor p h1 {
  color: aqua !important;
  font-size: 2px !important;
}


figcaption {
  margin: 10px;
  font-weight: 600;
  font-size: 20px;
  clear: both;
}
figcaption img{
  margin: 5px;
  
}


.tooltip::before {
  content: attr(title);
}

button[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

button[data-tooltip]::before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: rgba(0, 0, 0, 0.699);
  color: white;
  font-size: 80%;
  position: absolute;
  padding: 2px 9px 2px 9px;
  bottom: -1.6em;
  left: 100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border-radius: 4px;
  z-index: 30;
  visibility: hidden;
  font-size: 18px;
  font-weight: 700;
}
[data-title] {
  position: relative;
}

body {
  background: #f3f1f2;
  font-family: "Poppins"!important;
}



#root .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

#root .ql-snow.ql-toolbar {
  display: block;
  background: white !important;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}


#root .ql-editor {
  min-height: 18em;
}
.fade-in-out-text {
  animation: fadeInOut 1s infinite;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0; 
  }
  50% {
    opacity: 1; 
  }
}

@media screen and (max-width:1890px) and (min-width: 1800px) {
  .filter-list {
    top: 238px;
    left: 46%;
  }

  .border-list {
    top: 238px;
    left: 53.5%; 
  }
  .border-size-list{
    top: 128px;
    left: 66.5%; 
  }
  .shadow-list{
    top: 238px;
    left: 66%; 
  }
}
@media screen and (max-width: 1799px) and (min-width:1520px) {
  .filter-list {
    top: 238px;
    left: 49.5%;
  }

  .border-list {
    top: 238px;
    left: 58%; 
  }
  .border-size-list{
    top: 128px;
    left: 66.5%; 
  }
  .shadow-list{
    top: 238px;
    left: 70.8%; 
  }
} 


 
  p,
  ul,
  ol,
  dl,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  form,
  table {
    margin: 0;
  }
  
  .ql-toolbar.ql-snow {
    /* border: none; */
  }
  
  .canvas-container {
    width: 100%;
    position: relative;
    /* overflow: hidden; */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    background-color: #bd2525;
  }
  
  .toolbar-container {
    display: flex;
    width: 100%;
    background: white;
    margin-bottom: 10px;
    user-select: none;
  }
  
  .toolbar-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    margin-right: 5px;
    border-radius: 3px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  
  .toolbar-item:hover {
    background: rgb(228, 228, 228);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  .quill-container {
    width: 100%;
  }
  
  .quill-container .ql-editor {
    padding: 0;
  }
  
  .quill-container .ql-container {
    border: 0 !important;
  }
  
  .item-container {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .quill-container {
    height: 100%;
    width: 100%;
  }
  
  .showHandles .handle {
    display: block;
  }
  
  .handle {
    display: none;
    width: 10px !important;
    height: 10px !important;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ccc;
    position: absolute;
    box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
  }
  
  .long-handle {
    height: 15px !important;
    width: 5px !important;
  }
  
  .long-handle-horizontal {
    height: 5px !important;
    width: 15px !important;
  }
  
  .right-handle.bottom-handle {
    transform: translate(-4px, -4px);
  }
  
  .right-handle.top-handle {
    transform: translate(-4px, 4px);
  }
  
  .left-handle.bottom-handle {
    transform: translate(4px, -4px);
  }
  
  .left-handle.top-handle {
    transform: translate(4px, 4px);
  }
  
  .long-handle-horizontal.bottom-handle,
  .long-handle-horizontal.top-handle {
    left: 50% !important;
    transform: translateX(-8px);
  }
  
  .long-handle.left-handle,
  .long-handle.right-handle {
    top: 50% !important;
    transform: translateY(-8px);
  }
  
  .image-upload-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #imageFile {
    display: none;
  }
  
  
  .ql-hidden {
    display: none;
  }
  .ql-out-bottom,
  .ql-out-top {
    visibility: hidden;
  }
  .ql-tooltip {
    position: absolute;
    transform: translateY(10px);
  }
  .ql-tooltip a {
    cursor: pointer;
    text-decoration: none;
  }
  .ql-tooltip.ql-flip {
    transform: translateY(-10px);
  }
  .ql-formats {
    display: inline-block;
    vertical-align: middle;
  }
  .ql-formats:after {
    clear: both;
    content: "";
    display: table;
  }
  .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
  .ql-stroke-miter {
    fill: none;
    stroke: #444;
    stroke-miterlimit: 10;
    stroke-width: 2;
  }
  .ql-fill,
  .ql-stroke.ql-fill {
    fill: #444;
  }
  .ql-empty {
    fill: none;
  }
  .ql-even {
    fill-rule: evenodd;
  }
  .ql-thin,
  .ql-stroke.ql-thin {
    stroke-width: 1;
  }
  .ql-transparent {
    opacity: 0.4;
  }
  .ql-direction svg:last-child {
    display: none;
  }
  .ql-direction.ql-active svg:last-child {
    display: inline;
  }
  .ql-direction.ql-active svg:first-child {
    display: none;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
  a {
    text-decoration: underline;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
  code,
  pre {
    background-color: #f0f0f0;
    border-radius: 3px;
  }
  pre {
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
  }
  code {
    font-size: 85%;
    padding: 2px 4px;
  }
  pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible;
  }
  
  .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle;
  }
  .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%;
  }
  .ql-picker-label::before {
    display: inline-block;
    line-height: 22px;
  }
  .ql-picker-options {
    background-color: #fff;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
  }
  .ql-picker-options .ql-picker-item {
    cursor: pointer;
    display: block;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .ql-picker.ql-expanded .ql-picker-label {
    color: #ccc;
    z-index: 2;
  }
  .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #ccc;
  }
  .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #ccc;
  }
  .ql-picker.ql-expanded .ql-picker-options {
    display: block;
    margin-top: -1px;
    top: 100%;
    z-index: 1;
  }
  .ql-color-picker,
  .ql-icon-picker {
    width: 28px;
  }
  .ql-color-picker .ql-picker-label,
  .ql-icon-picker .ql-picker-label {
    padding: 2px 4px;
  }
  .ql-color-picker .ql-picker-label svg,
  .ql-icon-picker .ql-picker-label svg {
    right: 4px;
  }
  .ql-icon-picker .ql-picker-options {
    padding: 4px 0px;
  }
  .ql-icon-picker .ql-picker-item {
    height: 24px;
    width: 24px;
    padding: 2px 4px;
  }
  .ql-color-picker .ql-picker-options {
    padding: 3px 5px;
    width: 152px;
  }
  .ql-color-picker .ql-picker-item {
    border: 1px solid transparent;
    float: left;
    height: 16px;
    margin: 2px;
    padding: 0px;
    width: 16px;
  }
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    right: 0;
    top: 50%;
    width: 18px;
  }
  .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
  .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
  .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""])::before,
  .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
  .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
  .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""])::before {
    content: attr(data-label);
  }
  .ql-picker.ql-header {
    width: 98px;
  }
  .ql-picker.ql-header .ql-picker-label::before,
  .ql-picker.ql-header .ql-picker-item::before {
    content: "Normal";
  }
  .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "Heading 1";
  }
  .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "Heading 2";
  }
  .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "Heading 3";
  }
  .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "Heading 4";
  }
  .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "Heading 5";
  }
  .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "Heading 6";
  }
  .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    font-size: 2em;
  }
  .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    font-size: 1.5em;
  }
  .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    font-size: 1.17em;
  }
  .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    font-size: 1em;
  }
  .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    font-size: 0.83em;
  }
  .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    font-size: 0.67em;
  }
  .ql-picker.ql-font {
    width: 108px;
  }
  .ql-picker.ql-font .ql-picker-label::before,
  .ql-picker.ql-font .ql-picker-item::before {
    content: "Sans Serif";
  }
  .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: "Serif";
  }
  .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
  .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: "Monospace";
  }
  .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    font-family: Georgia, Times New Roman, serif;
  }
  .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    font-family: Monaco, Courier New, monospace;
  }
  .ql-picker.ql-size {
    width: 98px;
  }
  .ql-picker.ql-size .ql-picker-label::before,
  .ql-picker.ql-size .ql-picker-item::before {
    content: "Normal";
  }
  .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: "Small";
  }
  .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: "Large";
  }
  .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
  .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: "Huge";
  }
  .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    font-size: 10px;
  }
  .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    font-size: 18px;
  }
  .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    font-size: 32px;
  }
  .ql-color-picker.ql-background .ql-picker-item {
    background-color: #fff;
  }
  .ql-color-picker.ql-color .ql-picker-item {
    background-color: #000;
  }
  .ql-toolbar {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 8px;
  }
  .ql-toolbar.ql-formats {
    margin-right: 15px;
  }
  .ql-toolbar.ql-picker-label {
    border: 1px solid transparent;
  }
  .ql-toolbar.ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
  }
  .ql-toolbar.ql-picker.ql-expanded .ql-picker-label {
    border-color: #ccc;
  }
  .ql-toolbar.ql-picker.ql-expanded .ql-picker-options {
    border-color: #ccc;
  }
  .ql-toolbar.ql-color-picker .ql-picker-item.ql-selected,
  .ql-toolbar.ql-color-picker .ql-picker-item:hover {
    border-color: #000;
  }
  .ql-toolbar + .ql-container {
    border-top: 0px;
  }
  .ql-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ddd;
    color: #444;
    padding: 5px 12px;
    white-space: nowrap;
  }
  .ql-tooltip::before {
    content: "Visit URL:";
    line-height: 26px;
    margin-right: 8px;
  }
  .ql-tooltip input[type="text"] {
    display: none;
    border: 1px solid #ccc;
    font-size: 13px;
    height: 26px;
    margin: 0px;
    padding: 3px 5px;
    width: 170px;
  }
  .ql-tooltip a.ql-preview {
    display: inline-block;
    max-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  .ql-tooltip a.ql-action::after {
    border-right: 1px solid #ccc;
    content: "Edit";
    margin-left: 16px;
    padding-right: 8px;
  }
  .ql-tooltip a.ql-remove::before {
    content: "Remove";
    margin-left: 8px;
  }
  .ql-tooltip a {
    line-height: 26px;
  }
  .ql-tooltip.ql-editing a.ql-preview,
  .ql-tooltip.ql-editing a.ql-remove {
    display: none;
  }
  .ql-tooltip.ql-editing input[type="text"] {
    display: inline-block;
  }
  .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "Save";
    padding-right: 0px;
  }
  .ql-tooltip[data-mode="link"]::before {
    content: "Enter link:";
  }
  .ql-tooltip[data-mode="formula"]::before {
    content: "Enter formula:";
  }
  .ql-tooltip[data-mode="video"]::before {
    content: "Enter video:";
  }
  a {
    color: #06c;
  }
  
  #toolbar {
    display: flex;
    gap: 8px;
    padding: 8px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  
  .toolbar-item {
    padding: 4px 8px;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    text-align: center;
  }
  
  .toolbar-item:hover {
    background-color: #0056b3;
    cursor: pointer;
  }
  
  .ql-bold, .ql-italic, .ql-underline {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  select {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
  }
  

  .dragging {
    opacity: 0.5; /* Visual feedback for dragging */
    border: 2px dashed #ccc;
  }
  
  img {
    max-width: 100%;
    /* margin: 10px; */
    cursor: move;
  }
  
  img[style*="float: left"] {
    margin-right: 10px;
  }
  
  img[style*="float: right"] {
    margin-left: 10px;
  }
  .ql-editor img {
    cursor: grab; /* Default cursor for draggable images */
    position: relative;
  }
  
  .ql-editor img:active {
    cursor: grabbing; /* Change cursor when dragging */
  }
  
  .ql-image-block {
    /* display: inline-block; */
    text-align: center;
    margin: 20px;
  }
  
  .ql-image-block img {
    /* max-width: 100%; */
    height: auto;
    display: block;
    margin: 4px auto;
  }
  
  .ql-image-block figcaption {
    text-align: center;
    font-size: 14px;
    color: #555;
    margin-top: 5px;
  }
  .figcaptionparent{
    width: min-content;
  }
  .ql-image-block img {
    margin: 10px;
    max-width: 100%;
  }

  /* General List Styles */
.filter-list,
.border-list,
.border-size-list,
.shadow-list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  justify-content: flex-start; /* Align items to the left */
  padding: 10px;
  position: absolute;
  z-index: 10; /* Ensure the list appears above the editor content */
  margin: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
  border-radius: 4px;
  width: auto; /* Adjusts width dynamically based on content */
  min-width: 300px;
}

/* List Items */
.filter-list li,
.border-list li,
.border-size-list li,
.shadow-list li {
  margin: 5px;
  cursor: pointer;
  list-style: none;
}

.filter-list li img,
.border-list li div,
.border-size-list li div,
.shadow-list li div {
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 4px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.filter-list li img:hover,
.border-list li div:hover,
.border-size-list li div:hover,
.shadow-list li div:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Position Lists Below Toolbar */
.filter-list {
  top: 30px; /* Adjusted for proper placement below toolbar */
  left: 10px; /* Example positioning; can be adjusted dynamically */
}

.border-list {
  top: 30px;
  left: 20px;
}

.border-size-list {
  top: 30px;
  left: 30px;
}

.shadow-list {
  top: 30px;
  left: 40px;
}

.quill-image-toolbar{

  display: none;
}

@media (max-width: 768px) {
  .filter-list,
  .border-list,
  .border-size-list,
  .shadow-list {
    width: 100%; 
    justify-content: center; 
  }

  .filter-list li img,
  .border-list li div,
  .border-size-list li div,
  .shadow-list li div {
    width: 50px;
    height: 50px; 
  }
}
