@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://unpkg.com/@webpixels/css@1.1.5/dist/index.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");



.screen {
  overflow-y: none !important;
}
body {
  background-color: #FFF !important;
}
.css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {
  border: none !important;
  padding: 0 !important;
  padding: 8px !important;
}


body {
  background: #f3f1f2;
  font-family: sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.marvel-device {
  transform: scale(0.65) translate(0px, -120px) !important;
}
.w-60 {
  width: 60%;
}

.wrapper {
  display: flex;
}



.contents {
  flex: 1; 
  height:100%;
  transition: padding-left 0.3s ease;
}
.sidebar.active + .contents{
  padding-left: 8%;
}
.sidebar {
  width: 14%;
}

@media only screen and (min-width: 768px) {
  .sidebar {
    width: 14%;
  }
}

@media only screen and (min-width: 992px) {
  .sidebar {
    width: 10%;
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar {
    width: 8%;
  }
}

@media only screen and (min-width: 1600px) {
  .sidebar {
    width: 4%;
  }
}



.navbar-nav {
    padding-left: 0;
  
  }

  .nav-item {
    margin-bottom: 10px; 
    margin-left: 20px;
  }

  .nav-link {
    color: #343a40;
    font-size: 16px; 
    padding: 8px 12px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 4px; 
  }

  .nav-link:hover, .nav-link:focus {
    background-color: #f8f9fa; 
    color: #007bff;
  }

  .nav-link i {
    font-size: 18px;
  }

  .nav-link span {
    font-size: 16px; 
  }

  .bi {
    font-size: 20px;
  }


  .App {
    font-family: sans-serif;
    text-align: center;
  }
 